<template>
	<el-form :model="studentForm" ref="studentForm" label-width="100px">
		<div v-for="(item, index) in studentDataForm" :key="index">
			<el-form-item :label="item.label" v-if="item.isInput != false" :rules="item.rules" :prop="item.attr">
				<uploaderimgfile v-if="item.type == 'img'" v-model="studentForm[item.attr]" :imageUrl="imageUrl" @handleAvatarSuccess="handleAvatarSuccess" />
				<el-select v-else-if="item.type == 'enum'" v-model="studentForm[item.attr]" :placeholder="item.placeholder" filterable>
					<el-option v-for="ite in item.dic" :key="ite.value" :label="ite.title" :value="ite.value"> </el-option>
				</el-select>
				<el-date-picker v-else-if="item.type == 'date'" type="date" :placeholder="item.placeholder" v-model="studentForm[item.attr]" :format="item.format" :value-format="item.valueformat"></el-date-picker>
				<el-input v-else-if="item.type == 'textarea'" type="textarea" v-model="studentForm[item.attr]" :placeholder="item.placeholder"></el-input>
				<el-switch v-else-if="item.type == 'switch'" v-model="studentForm[item.attr]"></el-switch>
				<el-input v-else v-model="studentForm[item.attr]" :placeholder="item.placeholder"></el-input>
			</el-form-item>
		</div>
		<el-form-item>
			<el-button type="primary" @click="submitForm('studentForm')">保存</el-button>
			<el-button @click="resetForm('studentForm')">重置</el-button>
		</el-form-item>
	</el-form>
</template>

<script>
export default {
	name: "saveStudent",
	props: {
		stuid: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			imageUrl: {
				Id: 0,
				Url: "",
			},
			selectClassDatalist: [],
			studentForm: {
				Id: 0,
				ClassId: "",
				StudentName: "",
				StudentIDCard: "",
				StudentPhone: "",
				StudentBirthday: "",
				StudentNumber: "",
				DormitoryNumber: "",
				StudentPhoto: "",
				StudentType: 0,
				Sex: 1,
				FamilyAddress: "",
				ResidenceAddress: "",
				IsHelpPoor: false,
				IsLeftBehindChildren: false,
				RemarkInfo: "",
			},
			studentDataForm: [
				{
					label: "Id",
					attr: "Id",
					isInput: false,
				},
				{
					label: "学生照片",
					attr: "StudentPhoto",
					rules: [{ required: false, message: "请上传学生照片" }],
					type: "img",
				},
				{
					label: "学生姓名",
					attr: "StudentName",
					rules: [
						{ required: true, message: "请输入学生姓名" },
						{ max: 10, message: "长度不大于10!" },
					],
					type: "text",
				},
				{
					label: "学生班级",
					attr: "ClassId",
					placeholder: "请选择学生班级",
					rules: [
						{
							required: true,
							message: "请选择学生班级",
							trigger: "change",
						},
					],
					type: "enum",
					dic: this.GetSelectClassDataList(),
					formatters: function(val) {
						var result = "";
						var data = this.GetSelectClassDataList();
						if (data && data.length > 0) {
							data.forEach((it) => {
								if (it.value == val) {
									result = it.title;
								}
							});
						}
						return result;
					},
				},
				{
					label: "学生类型",
					attr: "StudentType",
					placeholder: "请选择学生类型",
					rules: [
						{
							required: true,
							message: "请选择学生类型!",
							trigger: "change",
						},
					],
					type: "enum",
					dic: [
						{ value: 0, title: "走读生" },
						{ value: 1, title: "半走读" },
						{ value: 2, title: "住校生" },
					],
					formatters: function(val) {
						var result;
						switch (val) {
							case 0:
								result = "走读生";
								break;
							case 1:
								result = "半走读";
								break;
							case 2:
								result = "住校生";
								break;
							default:
								result = "未知";
								break;
						}
						return result;
					},
				},
				{
					label: "出生日期",
					attr: "StudentBirthday",
					placeholder: "出生日期",
					format: "YYYY 年 MM 月 DD 日",
					valueformat: "YYYY/MM/DD",
					rules: [
						{
							type: "date",
							required: false,
							message: "请选择出生日期",
							trigger: "change",
						},
					],
					type: "date",
				},
				{
					label: "性别",
					attr: "Sex",
					placeholder: "请选择学生性别",
					type: "enum",
					dic: [
						{ value: 1, title: "男" },
						{ value: 2, title: "女" },
						{ value: 3, title: "未知" },
					],
					formatters: function(val) {
						var result;
						switch (val) {
							case 1:
								result = "男";
								break;
							case 2:
								result = "女";
								break;
							default:
								result = "未知";
								break;
						}
						return result;
					},
				},
				{
					label: "身份证号",
					attr: "StudentIDCard",
					placeholder: "请输入身份证号",
					rules: [
						{
							required: false,
							trigger: "blur",
							message: "请输入身份证号!",
						},
						{
							pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
							message: "格式验证失败,请检查身份证号是否正确!",
							trigger: "blur",
						},
					],
				},
				{
					label: "手机号",
					attr: "StudentPhone",
					placeholder: "请输入手机号",
					rules: [
						{
							required: false,
							trigger: "blur",
							message: "请输入手机号!",
						},
						{
							pattern: /^1\d{10}$/,
							message: "格式验证失败,请检查手机号是否正确!",
							trigger: "blur",
						},
					],
				},
				{
					label: "学籍号",
					attr: "StudentNumber",
					placeholder: "请输入学籍号",
				},
				{
					label: "宿舍编号",
					attr: "DormitoryNumber",
					placeholder: "请输入宿舍编号",
				},
				{
					label: "居住地址",
					attr: "FamilyAddress",
					placeholder: "请输入居住地址（长度不大于100个字符）",
					rules: [
						{
							min: 0,
							max: 100,
							message: "长度不大于100个字符",
							trigger: "blur",
						},
					],
				},
				{
					label: "户籍地址",
					attr: "ResidenceAddress",
					placeholder: "请输入户籍地址（长度不大于100个字符）",
					rules: [
						{
							min: 0,
							max: 100,
							message: "长度不大于100个字符",
							trigger: "blur",
						},
					],
				},
				{ label: "是否精准扶贫", attr: "IsHelpPoor", type: "switch" },
				{
					label: "是否留守儿童",
					attr: "IsLeftBehindChildren",
					type: "switch",
				},
				{
					label: "备注",
					attr: "RemarkInfo",
					placeholder: "请输入备注（长度不大于100个字符）",
					rules: [
						{
							min: 0,
							max: 100,
							message: "长度不大于100个字符",
							trigger: "blur",
						},
					],
					type: "textarea",
				},
			],
		};
	},
	methods: {
		initEditStudentModel() {
			var that = this;
			if (that.$props.stuid != 0) {
				that.$API.Student.QueryByStudentId.get(that.$props.stuid).then((res) => {
					if (res) {
						that.imageUrl.Id = res.StudentPhoto;
						that.imageUrl.Url = res.StudentPhotoUrl;
						that.studentForm.Id = res.Id;
						that.studentForm.StudentName = res.StudentName;
						that.studentForm.StudentPhone = res.StudentPhone;
						that.studentForm.DormitoryNumber = res.DormitoryNumber;
						that.studentForm.StudentIDCard = res.StudentIDCard;
						that.studentForm.StudentBirthday = res.StudentBirthday;
						that.studentForm.StudentNumber = res.StudentNumber;
						that.studentForm.StudentPhoto = res.StudentPhoto;
						that.studentForm.StudentType = res.StudentType;
						that.studentForm.Sex = res.Sex;
						that.studentForm.FamilyAddress = res.FamilyAddress;
						that.studentForm.ResidenceAddress = res.ResidenceAddress;
						that.studentForm.IsHelpPoor = res.IsHelpPoor;
						that.studentForm.IsLeftBehindChildren = res.IsLeftBehindChildren;
						that.studentForm.RemarkInfo = res.RemarkInfo;
						that.studentForm.ClassId = res.ClassId;
					}
				});
			}
		},
		GetSelectClassDataList() {
			var data = [];
			this.$API.Classes.GetClassesDataList.get().then((res) => {
				if (res) {
					res.forEach((it) => {
						data.push({ value: it.Id, title: it.ClassName });
					});
				}
				this.initEditStudentModel();
			});
			return data;
		},
		handleAvatarSuccess(res) {
			if (res.Success && res.Code == 200) {
				this.imageUrl = res.Data;
				this.studentForm.StudentPhoto = this.imageUrl.Id;
			} else {
				console.log(res.Message);
			}
		},
		submitForm(formName) {
			var that = this;
			this.$refs[formName].validate((valid) => {
				if (valid) {
					that.$API.Student.AddOrUpdate.post(that.studentForm).then((res) => {
						if (res.Success) {
							that.$message.success(res.Message);
							that.$emit("studentSaveSuccess");
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		resetForm(formName) {
			this.imageUrl = {};
			this.$refs[formName].resetFields();
		},
	},
};
</script>

<style></style>
